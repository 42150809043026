import React from "react";
import { Spinner } from "reactstrap";

const Loader: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center bg-dark"
      style={{ color: "white" }}
    >
      <Spinner style={{ width: "2rem", height: "2rem" }} />
      <h5 className="pl-2 mb-0" style={{ padding: "1.3rem 0" }}>
        Loading....
      </h5>
    </div>
  );
};

export default Loader;
