import React from "react";

interface NoDataAvailableProps {
  componentName?: string;
}

const NoDataMessage: React.FC<NoDataAvailableProps> = ({ componentName }) => {
  return (
    <div className="card bg-dark text-center align-content-center">
      <div className="card-body">
        <div style={{ color: "white" }}>
          No {componentName && `${componentName} `}data available
        </div>
      </div>
    </div>
  );
};

export default NoDataMessage;
