import React from "react";

import { SubscriptionSchema as Subscription } from "@pollyslack/hub/src/features/types";
import { Organization } from "@pollyslack/hub/src/types";

import { SubscriptionsTable } from "../../../components/SubscriptionsTable";

import { list as listSubscriptions } from "../../../services/api/Subscription";
import Loader from "../../../common/Loader";

type SubscriptionsTabProps = {
  organization: Organization;
};

export const SubscriptionsTab: React.FunctionComponent<
  SubscriptionsTabProps
> = (props: SubscriptionsTabProps) => {
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([]);
  const [reloadToggle, setReloadToggle] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  setReloadToggle; // silence lint
  React.useEffect(() => {
    // Use a local variable in the closure to prevent setting state on an unmounted component
    let cancelled = false;
    async function loadUsers() {
      setIsLoading(true);
      const subscriptions = await listSubscriptions({
        orgId: props.organization._id,
      });
      if (!cancelled) {
        setSubscriptions(subscriptions);
      }
      setIsLoading(false);
    }
    loadUsers();
    return function cleanup() {
      cancelled = true;
    };
  }, [reloadToggle]);
  if (isLoading) {
    return <Loader />;
  }
  return <SubscriptionsTable subscriptions={subscriptions} />;
};
