import React, { useState } from "react";

import { Organization } from "@pollyslack/hub/src/types";

import { Code } from "../../../components/Code";
import { Date } from "../../../components/Date";
import { ExpanderContent, ExpanderToggle } from "../../../components/Expander";
import { ToolbarButton } from "../../../components/ToolbarButton";
import { SigmaDashboardLinks } from "../tabs/OrgTab";

type OrgDetailsProps = {
  organization: Organization & SigmaDashboardLinks;
  decryptedSlackBotToken?: string;
  onTokenDecrypt?: () => void;
};

export const OrgDetails: React.FunctionComponent<OrgDetailsProps> = (
  props: OrgDetailsProps,
) => {
  const organization = props.organization;
  const settings = props.organization.settings;
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>Enterprise ID</th>
            <td>
              {organization.settings.slackEnterpriseId ? (
                <>
                  {organization.settings?.slackEnterpriseId}
                  <a
                    href={organization?.enterpriseSigmaDashboard}
                    target="_blank"
                    className="pl-2"
                  >
                    <ToolbarButton
                      className="btn-outline-secondary"
                      icon="fa-external-link-alt"
                      title="Sigma Dashboard"
                      onClick={() => {}}
                    />
                  </a>
                </>
              ) : (
                "Not part of Enterprise Grid"
              )}
            </td>
          </tr>
          <tr>
            <th>ID</th>
            <td>
              {organization._id}
              <a
                href={organization?.orgSigmaDashboard}
                target="_blank"
                className="pl-2"
              >
                <ToolbarButton
                  className="btn-outline-secondary"
                  icon="fa-external-link-alt"
                  title="Sigma Dashboard"
                  onClick={() => {}}
                />
              </a>
            </td>
          </tr>
          <tr>
            <th>Name</th>
            <td>
              <img
                height={24}
                src={settings.iconImage}
                alt="icon"
                className="mr-2"
              />
              {organization.name}
            </td>
          </tr>
          <tr>
            <th>Domain</th>
            <td>{organization.domain}</td>
          </tr>
          <tr>
            <th>Plan</th>
            <td>{organization.settings.subscription?.tier}</td>
          </tr>
          <tr>
            <th>Created</th>
            <td>
              <Date date={organization.createDate} />
            </td>
          </tr>
          <tr>
            <th>Bot Name</th>
            <td>{settings.slackBotName}</td>
          </tr>
          <tr>
            <th>Bot Active</th>
            <td>{settings.slackBotTokenActive ? "True" : "False"} </td>
          </tr>
          <tr>
            <th>Bot ID</th>
            <td>{settings.slackBotUserId}</td>
          </tr>
          {(props.onTokenDecrypt !== null || props.decryptedSlackBotToken) && (
            <tr>
              <th>Bot Token</th>
              <td>
                {props.decryptedSlackBotToken ? (
                  <>{props.decryptedSlackBotToken}</>
                ) : (
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={props.onTokenDecrypt}
                  >
                    Decrypt Token
                  </button>
                )}
              </td>
            </tr>
          )}
          <tr>
            <th>Slack Workspace ID</th>
            <td>{settings.slackTeamId}</td>
          </tr>
          <tr>
            <th>Slack Plan</th>
            <td>{settings.plan}</td>
          </tr>
          <tr>
            <th>User Count</th>
            <td>{settings.cachedUserCount?.userCount}</td>
          </tr>
          <tr>
            <th>Billable User Count</th>
            <td>{settings.cachedBillableUserCount}</td>
          </tr>
          <tr>
            <th>Next Sync Date</th>
            <td>
              <Date date={organization.nextSyncDate} />
            </td>
          </tr>
        </tbody>
      </table>
      View raw json{" "}
      <ExpanderToggle
        expanded={expanded}
        toggle={() => setExpanded(!expanded)}
      />
      <ExpanderContent expanded={expanded}>
        <Code object={props.organization} />
      </ExpanderContent>
    </div>
  );
};
