import React from "react";

import { Organization, SlackRole, User } from "@pollyslack/hub/src/types";

import {
  BotColumns,
  UserColumns,
  UsersTable,
} from "../../../components/UsersTable";

import { list as listUsers } from "../../../services/api/User";
import { DocumentTabNav } from "../../../components/DocumentTabNav";
import { DocumentSearch } from "../../../components/DocumentSearch";
import { useDebounce } from "use-debounce";
import Loader from "../../../common/Loader";

type UsersTabProps = {
  organization: Organization;
  reload: () => Promise<void>;
};

export const UsersTab: React.FunctionComponent<UsersTabProps> = (
  props: UsersTabProps,
) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [tab, setTab] = React.useState<
    "all" | "pollyAdmins" | "slackAdmins" | "bots"
  >("all");

  const [search, setSearch] = React.useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isLoading, setIsLoading] = React.useState(false);
  const tabs = [
    {
      id: "all",
      label: "All Users",
      onClick: () => setTab("all"),
    },
    {
      id: "pollyAdmins",
      label: "Polly Admins",
      onClick: () => setTab("pollyAdmins"),
    },
    {
      id: "slackAdmins",
      label: "Slack Admins",
      onClick: () => setTab("slackAdmins"),
    },
    {
      id: "bots",
      label: "Bots",
      onClick: () => setTab("bots"),
    },
  ];

  React.useEffect(() => {
    // Use a local variable in the closure to prevent setting state on an unmounted component
    let cancelled = false;
    async function loadUsers() {
      setIsLoading(true);
      const users = await listUsers({
        search: props.organization._id,
        namePattern: debouncedSearch,
        onlyPollyAdmins: tab === "pollyAdmins",
        onlySlackAdmins: tab === "slackAdmins",
        onlyBots: tab === "bots",
      });
      if (!cancelled) {
        setUsers(users);
      }
      setIsLoading(false);
    }
    loadUsers();
    return function cleanup() {
      cancelled = true;
    };
  }, [props.organization._id, tab, debouncedSearch]);

  let filteredUsers: User[] = [];
  switch (tab) {
    case "all":
      filteredUsers = users;
      break;
    case "pollyAdmins":
      filteredUsers = users.filter((u) => u.profile.orgAdmin);
      break;
    case "slackAdmins":
      filteredUsers = users.filter(
        (u) => u.profile.slackRole >= SlackRole.ADMIN,
      );
      break;
    case "bots":
      const sortKey = (user: User): string =>
        `${
          (user.profile.firstName ? user.profile.firstName + " " : "") +
            (user.profile.lastName ?? "") || user.profile.name
        }`.toLowerCase();
      filteredUsers = users
        .filter((u) => u.profile.is_bot)
        .sort((u1, u2) => (sortKey(u1) > sortKey(u2) ? 1 : -1));
  }

  const columnOverwrites: UserColumns & BotColumns =
    tab === "bots"
      ? {
          colEmail: false,
          colTitle: false,
          colPollyAdmin: false,
          colSlackRole: false,
          colInstalledAt: true,
        }
      : {};
  return (
    <>
      <div className="row ml-0">
        <DocumentTabNav initialTab={tab} tabs={tabs} />
        <div className="d-flex ml-4" style={{ gap: "12px" }}>
          <DocumentSearch
            typeName={"User"}
            placeholder={"User name or title"}
            numFound={filteredUsers.length}
            omitDeleted
            onSubmit={(searchParams) => setSearch(searchParams.search)}
            loading={false}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <UsersTable
          users={filteredUsers}
          colOrg={false}
          {...columnOverwrites}
        />
      )}
    </>
  );
};
