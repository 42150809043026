import React from "react";

import { Team } from "@pollyslack/hub/src/types";

import { BoolCheck } from "../BoolCheck";
import { DocumentTable } from "../DocumentTable";
import { ChannelLink } from "../DocumentLink";
import NoDataMessage from "../../common/NoDataMessage";

export type TeamWithId = Team & { _id: string };
type AudienceTableProps = {
  teams: TeamWithId[];
  linkable?: boolean;
};

export const AudienceTable: React.FunctionComponent<AudienceTableProps> = (
  props: AudienceTableProps,
) => {
  const properties = [
    {
      title: "Team ID",
      render: (d: TeamWithId) =>
        props.linkable ? <ChannelLink docId={d._id} /> : d._id,
    },
    {
      title: "Slack ID",
      render: (d: TeamWithId) => d.slackId,
    },
    {
      title: "Title",
      render: (d: TeamWithId) => d.title,
    },
    {
      title: "Members",
      render: (d: TeamWithId) => d.numMembers,
    },
    {
      title: "Private",
      render: (d: TeamWithId) => <BoolCheck check={d.isPrivate} />,
    },
    {
      title: "Group",
      render: (d: TeamWithId) => <BoolCheck check={d.isGroup} />,
    },
    {
      title: "General",
      render: (d: TeamWithId) => <BoolCheck check={d.isGroup} />,
    },
    {
      title: "Polly Member",
      render: (d: TeamWithId) => <BoolCheck check={d.isMember} />,
    },
    {
      title: "Shared",
      render: (d: TeamWithId) => <BoolCheck check={d.isShared} />,
    },
    {
      title: "Ext Shared",
      render: (d: TeamWithId) => <BoolCheck check={d.isExtShared} />,
    },
    {
      title: "Grid Shared",
      render: (d: TeamWithId) => <BoolCheck check={d.isEGShared} />,
    },
    {
      title: "Deleted",
      render: (d: TeamWithId) => <BoolCheck check={d.deleted} />,
    },
  ];

  if (!props.teams.length) {
    return <NoDataMessage />;
  }

  return (
    <DocumentTable<TeamWithId> properties={properties} objects={props.teams} />
  );
};
