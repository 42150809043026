// import React from "react";

import { TriviaWithDetails } from "../../common/apiTypes";
import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import { OrganizationLink, TriviaLink } from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";
import NoDataMessage from "../../common/NoDataMessage";

type TriviaTableProps = {
  trivia: TriviaWithDetails[];
};

export const TriviaTable = (props: TriviaTableProps) => {
  const properties = [
    {
      title: "Trivia ID",
      render: (d: TriviaWithDetails) => (
        <TriviaLink docId={d._id}>{d._id}</TriviaLink>
      ),
    },
    {
      title: "Type",
      render: (d: TriviaWithDetails) =>
        d.gameType === "trivia" ? "Trivia Game" : "Awards",
    },
    {
      title: "Win Type",
      render: (d: TriviaWithDetails) => d.winType,
    },
    {
      title: "Org ID",
      render: (d: TriviaWithDetails) => <OrganizationLink docId={d.orgId} />,
    },
    {
      title: "Sender ID",
      render: (d: TriviaWithDetails) => d.userProfile.user_id,
    },
    {
      title: "Sender Name",
      render: (d: TriviaWithDetails) => d.userProfile.real_name,
    },
    {
      title: "Members",
      render: (d: TriviaWithDetails) => d.channel.numMembers,
    },
    {
      title: "Questions",
      render: (d: TriviaWithDetails) => d.questions.length,
    },
    {
      title: "Participants",
      render: (d: TriviaWithDetails) => d.participantsCount,
    },
    {
      title: "Response Time (Seconds)",
      render: (d: TriviaWithDetails) => d.questionsActiveFor,
    },
    {
      title: "Deleted",
      render: (d: TriviaWithDetails) => <BoolCheck check={d.deleted} />,
    },
    {
      title: "Send Date",
      render: (d: TriviaWithDetails) => <Date date={d.createdAt} />,
    },
    {
      title: "Start Date",
      render: (d: TriviaWithDetails) => <Date date={d.startDate} />,
    },
  ];

  if (!props.trivia.length) {
    return <NoDataMessage componentName="trivia" />;
  }

  return (
    <DocumentTable<TriviaWithDetails>
      properties={properties}
      objects={props.trivia}
    />
  );
};
