import React, { useState } from "react";

import { User } from "@pollyslack/hub/src/types";

import { getSlackRoleDisplayText } from "@pollyslack/hub/src/utils";
import { OrganizationLink, UserLink } from "../DocumentLink";

import { BoolCheck } from "../BoolCheck";
import { ExpanderToggle } from "../Expander";
import { ExpandableCodeRow } from "../Tables/ExpandableCodeRow";
import { ToolbarButton } from "../ToolbarButton";

type UserDetailsProps = {
  user: User & { amplitudeProfile?: string };
};
export const UserDetails: React.FunctionComponent<UserDetailsProps> = (
  props: UserDetailsProps,
) => {
  const [expanded, setExpanded] = useState(false);
  const user = props.user;
  const profile = user?.profile;
  const userLinkLabel = `${profile.firstName ?? ""} ${
    profile.lastName ?? ""
  } (${profile.display_name ?? ""})`;
  return (
    <div>
      <table className="table table-sm table-hover">
        <tbody>
          <tr>
            <th>Name</th>
            <td>
              <UserLink docId={props.user._id}>
                <img
                  src={profile.slackAvatarUrls?.image_24}
                  className="mr-2"
                  alt=""
                />
                {userLinkLabel}
              </UserLink>
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{profile.email}</td>
          </tr>
          <tr>
            <th>Title</th>
            <td>{profile.title}</td>
          </tr>
          <tr>
            <th>Polly Admin</th>
            <td>
              <BoolCheck check={profile.orgAdmin} />
            </td>
          </tr>
          {user?.amplitudeProfile && (
            <tr>
              <th>Amplitude Profile</th>
              <td>
                <a href={user?.amplitudeProfile} target="_blank">
                  <ToolbarButton
                    className="btn-outline-primary"
                    icon="fa-external-link-alt"
                    title="Amplitude Profile"
                    onClick={() => {}}
                  />
                </a>
              </td>
            </tr>
          )}
          <tr>
            <th>Timezone</th>
            <td>{profile.tz}</td>
          </tr>
          <tr>
            <th>Org</th>
            <td>
              <OrganizationLink docId={profile.org}></OrganizationLink>
            </td>
          </tr>
          <tr>
            <th>Slack Role</th>
            <td>
              {profile.slackRole && getSlackRoleDisplayText(profile.slackRole)}
            </td>
          </tr>
          <tr>
            <th>Deleted</th>
            <td>
              <BoolCheck check={profile.deleted} />
            </td>
          </tr>
          <tr>
            <td>
              <ExpanderToggle
                label="Full Json"
                expanded={expanded}
                toggle={() => setExpanded(!expanded)}
              />
            </td>
            <td></td>
          </tr>
          <ExpandableCodeRow
            expanded={expanded}
            colSpan={2}
            object={props.user}
          />
        </tbody>
      </table>
    </div>
  );
};
