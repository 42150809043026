import React from "react";

import { Track } from "@pollyslack/hub/src/types";

import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import { OrganizationLink, TrackLink } from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";
import NoDataMessage from "../../common/NoDataMessage";

type TracksTableProps = {
  tracks: Track[];
};

export const TracksTable: React.FunctionComponent<TracksTableProps> = (
  props: TracksTableProps,
) => {
  const properties = [
    {
      title: "Track ID",
      render: (d: Track) => <TrackLink docId={d._id} />,
    },
    {
      title: "Title",
      render: (d: Track) => d.title,
    },
    {
      title: "Org ID",
      render: (d: Track) => <OrganizationLink docId={d.orgId} />,
    },
    {
      title: "Sender ID",
      render: (d: Track) => d.author,
    },
    {
      title: "Sender Name",
      render: (d: Track) => d.authorName,
    },
    {
      title: "Active",
      render: (d: Track) => <BoolCheck check={d.active} />,
    },
    {
      title: "Deleted",
      render: (d: Track) => <BoolCheck check={d.deleted} />,
    },
    {
      title: "Original Send",
      render: (d: Track) => <Date date={d.originalNextBriefDate} />,
    },
    {
      title: "Next Send",
      render: (d: Track) => <Date date={d.nextBriefDate} />,
    },
    {
      title: "Send by DM",
      render: (d: Track) => <BoolCheck check={d.dmAppeal} />,
    },
    {
      title: "Question Count",
      render: (d: Track) => d.numTrackPolls,
    },
  ];

  if (!props.tracks.length) {
    return <NoDataMessage componentName="tracks" />;
  }

  return (
    <DocumentTable<Track> properties={properties} objects={props.tracks} />
  );
};
