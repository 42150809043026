import React from "react";

import { BoolCheck } from "../BoolCheck";
import { Date as CustomDate } from "../Date";
import { OrganizationLink, QandALink } from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";
import { QnAWithDetails } from "../../common/apiTypes";
import NoDataMessage from "../../common/NoDataMessage";

type QandasTableProps = {
  qnadas: QnAWithDetails[];
};

export const QandasTable: React.FunctionComponent<QandasTableProps> = (
  props: QandasTableProps,
) => {
  const properties = [
    {
      title: "QnA ID",
      render: (d: QnAWithDetails) => <QandALink docId={d.id}>{d.id}</QandALink>,
    },
    {
      title: "Title",
      render: (d: QnAWithDetails) => d.title,
    },
    {
      title: "Org ID",
      render: (d: QnAWithDetails) => (
        <OrganizationLink docId={d.userProfile.profile.org} />
      ),
    },
    {
      title: "Sender ID",
      render: (d: QnAWithDetails) => d.userProfile.profile.user_id,
    },
    {
      title: "Members",
      render: (d: QnAWithDetails) => d.slackChannel?.numMembers,
    },
    {
      title: "Web Only",
      render: (d: QnAWithDetails) => <BoolCheck check={!d.slackChannel} />,
    },
    {
      title: "Responses",
      render: (d: QnAWithDetails) => d.responseCount,
    },
    {
      title: "Sender Name",
      render: (d: QnAWithDetails) => d.sender?.name,
    },
    {
      title: "Deleted",
      render: (d: QnAWithDetails) => (
        <BoolCheck check={d.state === "deleted"} />
      ),
    },
    {
      title: "Send Date",
      render: (d: QnAWithDetails) => (
        <CustomDate date={new Date(d.createdAt)} />
      ),
    },
    {
      title: "Close Date",
      render: (d: QnAWithDetails) => <CustomDate date={new Date(d.closeAt)} />,
    },
  ];

  const objects = props?.qnadas?.map((qna) => ({ ...qna, _id: qna.id }));

  if (!props.qnadas.length) {
    return <NoDataMessage componentName="Q&As" />;
  }

  return (
    <DocumentTable<QnAWithDetails> properties={properties} objects={objects} />
  );
};
