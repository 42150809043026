import React from "react";
import { PollWithDetails } from "../../common/apiTypes";

import { BoolCheck } from "../BoolCheck";
import { Date } from "../Date";
import {
  OrganizationLink,
  PollLink,
  TrackLink,
  WorkflowLink,
} from "../DocumentLink";
import { DocumentTable } from "../DocumentTable";
import NoDataMessage from "../../common/NoDataMessage";

type PollsTableProps = {
  polls: PollWithDetails[];
};

export const PollsTable: React.FunctionComponent<PollsTableProps> = (
  props: PollsTableProps,
) => {
  const properties = [
    {
      title: "Poll ID",
      render: (d: PollWithDetails) => (
        <PollLink docId={d._id}>{d.platformId}</PollLink>
      ),
    },
    {
      title: "Title",
      render: (d: PollWithDetails) => d.question.title,
    },
    {
      title: "Org ID",
      render: (d: PollWithDetails) => <OrganizationLink docId={d.orgId} />,
    },
    {
      title: "Sender ID",
      render: (d: PollWithDetails) => d.originUserId,
    },
    {
      title: "Members",
      render: (d: PollWithDetails) =>
        Array.isArray(d.channels) && d.channels.length > 0
          ? d.channels[0]?.numMembers
          : 0,
    },
    {
      title: "Votes",
      render: (d: PollWithDetails) => d.question.votes,
    },
    {
      title: "Sender Name",
      render: (d: PollWithDetails) => d.originUserName,
    },
    {
      title: "Deleted",
      render: (d: PollWithDetails) => <BoolCheck check={d.deleted} />,
    },
    {
      title: "Send Date",
      render: (d: PollWithDetails) => <Date date={d.assignDate} />,
    },
    {
      title: "Close Date",
      render: (d: PollWithDetails) => <Date date={d?.question?.closeDate} />,
    },
    {
      title: "Results Method",
      render: (d: PollWithDetails) => d.results,
    },
    {
      title: "Send by DM",
      render: (d: PollWithDetails) => <BoolCheck check={d.strictVoting} />,
    },
    {
      title: "Track ID",
      render: (d: PollWithDetails) => <TrackLink docId={d.trackId} />,
    },
    {
      title: "Workflow ID",
      render: (d: PollWithDetails) => <WorkflowLink docId={d.workflowId} />,
    },
  ];

  if (!props.polls.length) {
    return <NoDataMessage componentName="polls" />;
  }

  return (
    <DocumentTable<PollWithDetails>
      properties={properties}
      objects={props.polls}
    />
  );
};
