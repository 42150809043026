import React from "react";

import { User } from "@pollyslack/hub/src/types";
import { getSlackRoleDisplayText } from "@pollyslack/hub/src/utils";

import { BoolCheck } from "../BoolCheck";
import { OrganizationLink, UserLink } from "../DocumentLink";
import { DocumentTable, DocumentTableProperty } from "../DocumentTable";
import { licensesAssignedAtEntry } from "@pollyslack/hub/src/features/subscription/schema";
import NoDataMessage from "../../common/NoDataMessage";

type CommonColumns = {
  colName?: boolean;
  colActive?: boolean;
};

export type UserColumns = CommonColumns & {
  colEmail?: boolean;
  colTitle?: boolean;
  colOrg?: boolean;
  colPollyAdmin?: boolean;
  colSlackRole?: boolean;
  colLicensesAssignedAt?: licensesAssignedAtEntry;
};

export type BotColumns = CommonColumns & {
  colInstalledAt?: boolean;
};

const defaultColumns: UserColumns = {
  colEmail: true,
  colName: true,
  colTitle: true,
  colOrg: true,
  colPollyAdmin: true,
  colSlackRole: true,
  colActive: true,
};

type UsersTableProps = {
  users: User[];
  action?: (user: User) => React.ReactNode;
} & UserColumns &
  BotColumns;

export const UsersTable: React.FunctionComponent<UsersTableProps> = (
  props: UsersTableProps,
) => {
  const properties: DocumentTableProperty<User>[] = [
    {
      title: "Email",
      render: (d: User) => <UserLink docId={d._id}>{d.profile.email}</UserLink>,
      hide: !props.colEmail,
      style: { maxWidth: "25%" },
    },
    {
      title: "Name",
      render: (d: User) => (
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <img
            className="mr-4"
            src={d.profile.slackAvatarUrls?.image_32}
            alt=""
          />
          <div>
            <p className="mb-0">{`${
              (d.profile.firstName ? d.profile.firstName + " " : "") +
                (d.profile.lastName ?? "") || d.profile.name
            }`}</p>
            <p className="mb-0">
              {d.profile.display_name || d.profile.real_name}
            </p>
          </div>
        </div>
      ),
      hide: !props.colName,
      style: { maxWidth: "25%" },
    },
    {
      title: "Title",
      render: (d: User) => d.profile.title,
      hide: !props.colTitle,
      style: { maxWidth: "25%" },
    },
    {
      title: "Org",
      render: (d: User) => (
        <OrganizationLink docId={d.profile.org}>
          {d.profile.orgName}
        </OrganizationLink>
      ),
      hide: !props.colOrg,
      style: { maxWidth: "25%" },
    },
    {
      title: "Polly Admin",
      render: (d: User) => (
        <div className="ml-3">
          <BoolCheck check={d.profile.orgAdmin} />
        </div>
      ),
      hide: !props.colPollyAdmin,
      style: { maxWidth: "4em" },
    },
    {
      title: "Slack Role",
      render: (d: User) =>
        d.profile.slackRole ? getSlackRoleDisplayText(d.profile.slackRole) : "",
      hide: !props.colSlackRole,
    },
    {
      title: "Date Installed",
      render: (d: User) => new Date(d.createdAt).toLocaleDateString(),
      hide: !props.colInstalledAt,
    },
    {
      title: "Date License Assigned",
      render: (d: User) =>
        props.colLicensesAssignedAt && props.colLicensesAssignedAt[d._id]
          ? new Date(props.colLicensesAssignedAt[d._id]).toLocaleDateString()
          : "-",
      hide: !props.colLicensesAssignedAt,
    },
    {
      title: "Active",
      render: (d: User) => (!d.profile.deleted).toString(),
      hide: !props.colActive,
    },
  ];
  if (props.action) {
    properties.push({
      title: "",
      render: props.action,
      hide: false,
      style: { maxWidth: "4em" },
    });
  }
  if (!props.users.length) {
    return <NoDataMessage />;
  }
  return <DocumentTable<User> properties={properties} objects={props.users} />;
};

UsersTable.defaultProps = {
  ...defaultColumns,
};
