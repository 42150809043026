import Stripe from "stripe";
import * as querystring from "querystring";
import _ from "lodash";
import { locations } from "../instrumentation/userEvents/types";
import { CategoryId } from "@pollyslack/types/src/templates";
import { GlobalTemplateIds } from "../globalTemplatesContent";

export const NODE_ENV = process.env.NODE_ENV as
  | "development"
  | "production"
  | "test"
  | "staging";
export const HUBSPOT_PORTAL_ID = process.env.HUBSPOT_PORTAL_ID;
// The main KMS key, used to encrypt slack tokens
export const AWS_KMS_KEY_ID = process.env.AWS_KMS_KEY_ID;
// Separate KMS key, used to encrypt sharedSecret for Atlassian Connect
export const AWS_KMS_ATLASSIAN_KEY_ID = process.env.AWS_KMS_ATLASSIAN_KEY_ID;
export const QUEUE_URL = process.env.QUEUE_URL;
export const INTUIT_AGENT_SUPPORT_WORKFLOW_ID =
  process.env.INTUIT_AGENT_SUPPORT_WORKFLOW_ID;
export const INTUIT_AGENT_SUPPORT_TEST_WORKFLOW_ID =
  process.env.INTUIT_AGENT_SUPPORT_TEST_WORKFLOW_ID;

// env var to identify non-prod environments, e.g. "beta", "staging", "or", "samir", etc.
export const NONPROD_MODIFIER = process.env.NONPROD_MODIFIER || "";

// This is the unique key identifying our prod Jira Service Desk app
// non-prod apps have "-staging", "-beta", etc. added at the end.
export const JSD_APP_KEY_BASE = "ai.polly.jira-service-desk-integration";

export const NUM_DAYS_TRIAL_COOLDOWN = 365;
export const NUM_DAYS_FREE_TRIAL = 7;
export const NUM_DAYS_MANAGED_TRIAL = 14;
export const NUM_DAYS_ONE_TIME_SUB = 7;
export const EDU_PROMO_CODE = "EDUNPO50";

export const ROOT_URL: string =
  NODE_ENV === "test" ? "https://test.root.url" : process.env.ROOT_URL;

export const GLOBAL_TEMPLATES_URL = ({
  source,
  templateId,
  categoryId,
  utm_source = "slack",
  utm_medium = "app",
  utm_campaign,
  utm_term,
}: {
  templateId?: GlobalTemplateIds;
  categoryId?: CategoryId;
  source?: locations;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
} = {}) => {
  return `${ROOT_URL}/templates?${querystring.stringify(
    _.pickBy(
      {
        source,
        templateId,
        categoryId,
        utm_source,
        utm_campaign,
        utm_medium,
        utm_term,
      },
      _.identity,
    ),
  )}`;
};
export const OPEN_FORUM_INTRO_URL =
  "https://www.polly.ai/introducing-open-forum";
export const WEB_VOTING_HELP_URL =
  "https://www.polly.ai/help/slack/polly-link-sharing-and-web-voting";
export const MARKETING_URL = "https://www.polly.ai";

export const REDIS_URL = process.env.REDIS_URL;
export const SLACK_APP_ID = process.env.SLACK_APP_ID;
export const STRIPE_TOKEN: string = process.env.STRIPE_TOKEN;
export const STRIPE_PUB_TOKEN: string = process.env.STRIPE_PUB_TOKEN;
export const STRIPE_API_VERSION: Stripe.LatestApiVersion = "2022-11-15";
export const STRIPE_WEBHOOK_SECRET: string = process.env.STRIPE_WEBHOOK_SECRET;
export const LAUNCHDARKLY_SDKID: string = process.env.LAUNCHDARKLY_SDKID;
export const LAUNCHDARKLY_CLIENTID: string = process.env.LAUNCHDARKLY_CLIENTID;

export const ORG_ID_WITH_BLOCK_LEVEL_LOGGING: string =
  process.env.ORG_ID_WITH_BLOCK_LEVEL_LOGGING;
export const CHANNEL_ID_WITH_BLOCK_LEVEL_LOGGING: string =
  process.env.CHANNEL_ID_WITH_BLOCK_LEVEL_LOGGING;
export const SLACK_TEAM_ID_WITH_BLOCK_LEVEL_LOGGING: string =
  process.env.SLACK_TEAM_ID_WITH_BLOCK_LEVEL_LOGGING;

export const AMPLITUDE_PROJECT_ID = process.env.AMPLITUDE_PROJECT_ID;
export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
export const AMPLITUDE_SECRET_KEY = process.env.AMPLITUDE_SECRET_KEY;
export const AMPLITUDE_NPS_COHORT_ID = process.env.AMPLITUDE_NPS_COHORT_ID;
export const AMPLITUDE_DEMO_CTA_ENABLED_COHORT_ID =
  process.env.AMPLITUDE_DEMO_CTA_ENABLED_COHORT_ID;
export const AMPLITUDE_CHECKOUT_CONFIRMATION_COHORT_ID =
  process.env.AMPLITUDE_CHECKOUT_CONFIRMATION_COHORT_ID;

export const IS_SINGLE_TENANT = process.env.IS_SINGLE_TENANT;
export const BOOK_DEMO_URL =
  "https://www.polly.ai/supercharge-slack-with-polly";
export const WORKFLOWS_URL = "https://www.polly.ai/workflows";
export const DEMOGRAPHICS_URL = "https://www.polly.ai/demographics";
export const EXPORTS_URL = "https://www.polly.ai/exports";
export const API_WEBHOOKS_URL = "https://www.polly.ai/api-webhooks";
export const QNA_URL = "https://www.polly.ai/qa";
export const OPEN_FORUM_DOCS_URL = (utm_medium: string, utm_campaign: string) =>
  `https://www.polly.ai/open-forum?utm_source=slack&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
export const QNA_DOCS_URL = (utm_medium: string, utm_campaign: string) =>
  `https://app.polly.ai/qna?source=apphomecta&utm_source=slack&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;
export const ENTERPRISE_WEB_URL = (utm_medium: string, utm_campaign: string) =>
  `https://www.polly.ai/slack-enterprise?utm_source=slack&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`;

export const STRIPE_SYNTHETIC_TEST_CUSTOMER_EMAIL: string =
  process.env.STRIPE_SYNTHETIC_TEST_CUSTOMER_EMAIL;

export const SIGMA_ORG_DASHBOARD_URL = process.env.SIGMA_ORG_DASHBOARD_URL;
