import React from "react";
import {
  SubscriptionSchema as Subscription,
  SubscriptionSchema,
} from "@pollyslack/hub/src/features/types";
import { EnterpriseGrid, Organization } from "@pollyslack/hub/src/types";
import { SlackEnterpriseId } from "@pollyslack/hub/src/slackIdTypes";

import { CreateEnterpriseSubscriptionModal } from "../../../components/CreateEnterpriseSubscriptionModal";
import { SubscriptionActionsToolbar } from "../../../components/SubscriptionActionsToolbar";
import { UpdateSubscriptionModal } from "../../../components/UpdateSubscriptionModal";

import { info, createSubscription } from "../../../services/api/Enterprise";
import {
  setEnterpriseSubActiveState,
  setEnterpriseTier,
} from "../../../services/api/Organization";
import { infoBySlackEnterpriseId } from "../../../services/api/Subscription";

import { PlanParams } from "./PlanParams";
import { decryptEnterpriseGridToken } from "./helpers";
import { EnterpriseDetails } from "../components/EnterpriseDetails";
import { SubscriptionDetails } from "../components/SubscriptionDetails";
import { Spinner } from "reactstrap";

type EnterpriseTabProps = {
  organization: Organization;
};

export const EnterpriseTab: React.FunctionComponent<EnterpriseTabProps> = (
  props: EnterpriseTabProps,
) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [enterprise, setEnterprise] = React.useState<EnterpriseGrid>();
  const [decryptedToken, setDecryptedToken] = React.useState<string>();
  const [subscription, setSubscription] = React.useState<Subscription>();
  const [reloadToggle, setReloadToggle] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  setReloadToggle; // silence lint
  React.useEffect(() => {
    // Use a local variable in the closure to prevent setting state on an unmounted component
    let cancelled = false;
    async function load() {
      setIsLoading(true);
      if (!props.organization.settings.slackEnterpriseId) {
        setIsLoading(false);
        return;
      }
      let enterprise: EnterpriseGrid;
      try {
        enterprise = await info(props.organization.settings.slackEnterpriseId);
      } catch (e) {
        // not found? that's ok
      }
      let subscription: SubscriptionSchema;
      try {
        subscription = await infoBySlackEnterpriseId({
          id: props.organization.settings.slackEnterpriseId,
        });
      } catch (e) {
        // not found? that's ok
      }
      if (!cancelled) {
        setEnterprise(enterprise);
        setSubscription(subscription);
      }
      setIsLoading(false);
    }
    load();
    return function cleanup() {
      cancelled = true;
    };
  }, [reloadToggle, props.organization.settings.slackEnterpriseId]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center pt-5">
        <Spinner style={{ width: "3rem", height: "3rem" }} />
        <div className="pl-2" style={{ fontSize: "2rem" }}>
          Loading...
        </div>
      </div>
    );
  }
  if (!props.organization.settings.slackEnterpriseId) {
    return <div>Organization is not part of an Enterprise Grid</div>;
  }
  return (
    <>
      {!enterprise && (
        <div>
          No EnterpriseGrid found for{" "}
          {props.organization.settings.slackEnterpriseId}
        </div>
      )}
      {enterprise && (
        <EnterpriseDetails
          enterprise={enterprise}
          decryptedSlackBotToken={decryptedToken}
          onTokenDecrypt={async () =>
            setDecryptedToken(
              await decryptEnterpriseGridToken(
                props.organization.settings.slackEnterpriseId,
              ),
            )
          }
        />
      )}
      {subscription && (
        <>
          <SubscriptionActionsToolbar
            onUpdateSubscriptionClick={() => setModalOpen(true)}
            onDeactivateClick={async () => {
              if (subscription.active) {
                await enterpriseSubSetActive(subscription._id, false);
                setReloadToggle(!reloadToggle);
                setModalOpen(false);
              }
            }}
            onReactivateClick={async () => {
              if (!subscription.active) {
                await enterpriseSubSetActive(subscription._id, true);
                setReloadToggle(!reloadToggle);
                setModalOpen(false);
              }
            }}
          />
          <UpdateSubscriptionModal
            isOpen={modalOpen}
            subscription={subscription}
            onClose={() => {
              setModalOpen(false);
            }}
            onSubmit={async (params) => {
              await setEnterprisePlan(props.organization, params);
              setModalOpen(false);
            }}
          />
          <SubscriptionDetails subscription={subscription} />
        </>
      )}
      {!subscription && (
        <>
          <SubscriptionActionsToolbar
            onCreateClick={async () => {
              setModalOpen(true);
            }}
          />
          <CreateEnterpriseSubscriptionModal
            isOpen={modalOpen}
            onClose={() => {
              setModalOpen(false);
            }}
            onSubmit={async (params) => {
              await createEnterpriseSub({
                slackEnterpriseId: props.organization.settings
                  .slackEnterpriseId as SlackEnterpriseId,
                orgId: props.organization._id,
                expiration: params.expiration,
                stripeCustomerId: params.stripeCustomerId,
                memo: params.memo,
              });
              setModalOpen(false);
            }}
          />
        </>
      )}
    </>
  );
};

async function enterpriseSubSetActive(subscriptionId: string, active: boolean) {
  try {
    await setEnterpriseSubActiveState({
      _id: subscriptionId,
      active,
    });
  } catch (e) {
    alert("Error: " + e);
  }
}

async function setEnterprisePlan(org: Organization, params: PlanParams) {
  try {
    await setEnterpriseTier({
      slackEnterpriseId: org.settings.slackEnterpriseId,
      orgId: org._id,
      ...params,
    });
  } catch (e) {
    alert("Error: " + e);
  }
}

async function createEnterpriseSub(params: {
  slackEnterpriseId: SlackEnterpriseId;
  orgId: string;
  stripeCustomerId: string;
  memo: string;
  expiration?: Date;
}) {
  const { slackEnterpriseId, orgId, stripeCustomerId, memo, expiration } =
    params;
  await createSubscription(
    slackEnterpriseId,
    orgId,
    stripeCustomerId,
    memo,
    expiration?.toISOString(),
  );
}
