import React from "react";

import { Organization, Team as Channel } from "@pollyslack/hub/src/types";

import { AudienceTable } from "../../../components/AudienceTable";

import { list as listChannels } from "../../../services/api/Channel";
import { DocumentTabNav } from "../../../components/DocumentTabNav";
import { DocumentSearch } from "../../../components/DocumentSearch";
import { useDebounce } from "use-debounce";
import Loader from "../../../common/Loader";

type ChannelsTabProps = {
  organization: Organization;
  reload: () => Promise<void>;
};

export const ChannelsTab: React.FunctionComponent<ChannelsTabProps> = (
  props: ChannelsTabProps,
) => {
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [tab, setTab] = React.useState<
    "all" | "pollyAdmins" | "slackAdmins" | "bots"
  >("all");

  const [search, setSearch] = React.useState("");
  const [debouncedSearch] = useDebounce(search, 500);
  const [isLoading, setIsLoading] = React.useState(false);
  const tabs = [
    {
      id: "all",
      label: "All Channels",
      onClick: () => setTab("all"),
    },
  ];

  React.useEffect(() => {
    // Use a local variable in the closure to prevent setting state on an unmounted component
    let cancelled = false;
    async function loadChannels() {
      setIsLoading(true);
      const ch = await listChannels({
        search: props.organization._id,
        namePattern: debouncedSearch,
        ignoreDms: true,
      });
      if (!cancelled) {
        setChannels(ch);
      }
      setIsLoading(false);
    }
    loadChannels();
    return function cleanup() {
      cancelled = true;
    };
  }, [props.organization._id, tab, debouncedSearch]);

  let filteredChannels: Channel[] = [];
  switch (tab) {
    case "all":
      filteredChannels = channels;
      break;
  }

  return (
    <>
      <div className="row ml-0">
        <DocumentTabNav initialTab={tab} tabs={tabs} />
        <div className="d-flex ml-4" style={{ gap: "12px" }}>
          <DocumentSearch
            typeName={"Channel"}
            placeholder={"Channel name or id"}
            numFound={filteredChannels.length}
            omitDeleted
            onSubmit={(searchParams) => setSearch(searchParams.search)}
            loading={false}
          />
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <AudienceTable teams={filteredChannels} linkable={false} />
      )}
    </>
  );
};
